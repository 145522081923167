.container {
  display: flex;
  justify-content: center;
}

.titleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Kaoly-Demo-Regular', sans-serif;
  font-size: 2.3rem;
  color: #c9b36e;
}

.about {
  width: 100%;
  max-width: 1024px;
  padding: 34px;
}

.about a {
  color: #c9b36e;
}

.about p {
  margin-top: 0;
  line-height: 24px;
  letter-spacing: 0.01rem;
  color: #e3e3e3;
  font-size: 1rem;
}

.about p:first-of-type {
  text-indent: 40px;
}

.image {
  max-width: 60%;
  float: right;
  margin: 0 0 20px 20px;
}

.description {
  width: 100%;
}

.socials {
  z-index: 1;
  display: flex;
  gap: 20px;
  margin: 34px 0 0 0;
  width: 100%;
  justify-content: center;
}

.social svg {
  height: 20px;
  width: 20px;
}

.social {
  background-color: #c9b36e;
  border-radius: 100%;
  border: none;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.1s ease-in-out;
}

.social:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 768px) {
  .socials {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .image {
    width: 100%;
    max-width: 100%;
    float: none;
    margin: 0 0 20px 0;
  }

  .about {
    padding: 10px;
  }
}