@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700");
@font-face {
  font-family: 'Kaoly-Demo-Regular';
  src: url('../../public/fonts/Kaoly-Demo-Regular.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #e3e3e3;
}

code {
  font-family: "Montserrat", sans-serif;
}

* {
  box-sizing: border-box;
}

.hamburger-react {
  color: #e3e3e3;
}

a {
  color: inherit;
  text-decoration: none;
}

#wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  perspective-origin: 50% 50%;
  perspective: 800px;
}


.fadeIn {
  animation: fadein 0.3s;
  -moz-animation: fadein 0.3s;
  /* Firefox */
  -webkit-animation: fadein 0.3s;
  /* Safari and Chrome */
  -o-animation: fadein 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  #wrap {
    display: none;
  }
  .hero {
    margin-left: -15px;
  }
}
