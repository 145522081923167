.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'Kaoly-Demo-Regular', 'sans-serif';
  color: #c9b36e;
  font-size: 44px;
}