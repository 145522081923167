.heroContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation: fadein 3s;
  -moz-animation: fadein 3s;
  /* Firefox */
  -webkit-animation: fadein 3s;
  /* Safari and Chrome */
  -o-animation: fadein 3s;
}

.heroContainer img {
  max-width: 100%;
  max-height: 100%;
  /* margin-top: 350px; */
}

.heroTitleContainer {
  position: fixed;
  top: 120px;
  right: 120px;
  font-size: 44px;
}

.heroTitle {
  z-index: 1000;
  color: #c9b36e;
  font-size: 74px;
  font-family: 'Kaoly-Demo-Regular', 'sans-serif';
}

.socials {
  z-index: 1;
  display: flex;
  gap: 20px;
  position: absolute;
  top: 100px;
  right: 34px;
}

.social svg {
  height: 20px;
  width: 20px;
}

.social {
  background-color: #c9b36e;
  border-radius: 100%;
  border: none;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.1s ease-in-out;
}

.social:hover {
  transform: scale(1.1);
}

.bookCta {
  position: absolute;
  top: 110px;
  left: 4.5%;
  border: none;
  transition: transform 0.2s ease-in-out;
  height: 175px;
  width: 175px;
  animation: scaleUpDown 2s infinite ease-in-out;
  box-shadow: 0px 0px 15px #c9b36e;
  border-radius: 50%;
}

.bookCta img {
  border-radius: 50%;
}

.bookCta:hover {
  transform: rotate(180deg);
  box-shadow: 0px 0px 35px #c9b36e;
}

@media only screen and (min-width: 2000px) {
  .bookCta {
    top: 110px;
  }
}

@media only screen and (max-width: 1024px) {
  .bookCta {
    top: 110px;
  }
}

@media only screen and (max-width: 768px) {
  .socials {
    display: none;
  }

  .heroContainer {
    height: calc(100vh - 80px) !important;
  }

  .bookCta {
    top: 110px;
    height: 150px;
    width: 150px;
  }
}

@media only screen and (max-width: 414px) {
  .bookCta {
    top: 110px;
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 320px) {
  .bookCta {
    top: 110px;
    height: 80px;
    width: 80px;
  }
}


.loadingScreen {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #c9b36e;
  font-size: 1rem;
}

@keyframes scaleUpDown {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}